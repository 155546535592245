import React from 'react';
import { connect } from 'react-redux';

import Header from 'components/App/Header';
import Sidenav from 'components/App/Sidenav';
import Footer from 'components/App/Footer';
import {setUserDiscovered} from 'reducers/profile';
import { registerRefreshTimeout, getSessionType, refresh } from "@cpmdevops/cpm-auth";
import config from "../constants/Config";

const SSO_URL = window.ssoRedirect || config.ssoRedirect;

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

class MainApp extends React.Component {

  constructor() {
    super();
    this.state = {
      isLoading: true
    }
  }

  async componentDidMount() {
    const requireAuth = await this.requireAuthentication();
    if (requireAuth) {
      const {history} = this.props;
      history.push('/login');
    }
  }

  updateReduxJWT() {
    const {setUserDiscovered} = this.props;
    console.log("Updating the Redux JWT");
    const ssoCookie = getCookie('cpm-sso-token');
    const decodedJWT = parseJwt(ssoCookie);
    setUserDiscovered({ JWT: ssoCookie, decodedJWT });
  }

  async requireAuthentication() {

    //  Get the Current Access Token
    let ssoCookie = getCookie('cpm-sso-token');

    //  Handle Initial Session Refresh (if needed)
    const sessionType = getSessionType();
    console.log(`Session Type: ${ sessionType }`);
    if (sessionType === "refresh") {
      console.log(`Refresh Session Identified`);
      if (!ssoCookie) {
        console.log(`No Access Token Identified.  Attempting Refresh...`);
        try {
          await refresh(ssoRedirect, false, true);
          console.log(`Refresh Succeeded`);
        } catch (err) {
          console.warn("Refresh Failed")
        }
      }
    }

    //  Register Refresh Heartbeat (Updates the Redux Store)
    registerRefreshTimeout({redirect: false, skipInitial: true, ssoUrl: ssoRedirect, callback: () => {
      console.log("C3PO Refresh Succeeded");
      this.updateReduxJWT();
      console.log("C3PO Redux Updated with new JWT");
    }});

    ssoCookie = getCookie('cpm-sso-token');

    if (ssoCookie) {
      this.updateReduxJWT();
      this.setState({ isLoading: false });
      console.log("Authenticated Succeeded");
      return false;
    } else {
      console.warn("Authenticated Failed: " + ssoCookie);
      return true;
    }
  }

  render() {
    const { children } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return <div>Refreshing Session</div>
    }

    return (
      <div className="main-app-container">
        <Sidenav />
        <section id="page-container" className="app-page-container">
          <Header />
          <div className="app-content-wrapper">
            <div className="app-content">
              <div className="full-height">
                {children}
              </div>
            </div>
            <Footer />
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  existingJWT: state.profile.JWT
});
const mapDispatchToProps = dispatch => ({
  setUserDiscovered: (payload) => dispatch(setUserDiscovered(payload))
});

export const AppContainer = connect(mapStateToProps,mapDispatchToProps)(MainApp);
